@use 'vars' as *;

.btn{
    border:1px solid #FFF;
    padding:.5rem 2.5rem;
    border-radius: 5px;
    transition: all .2s ease-in-out;
    text-decoration: none;
    color:black;
    display: inline-block;
    font-weight: 500;
    font-weight: 14px;
    &:hover{
        transform: scale(1.1);
        
    }
}
.bg-trans-black{
    background: rgba(43, 38, 38, .31);

}
.bg-white{
    background: rgb(255, 255, 255);
}
.bg-blue{
    background: rgb(12, 24, 62 );
}
.border-black{
    border:1px solid black;
}
.text-blue{
    color:$primary-color;
}
.text-white{
    color: rgb(255, 255, 255);
}
.text-black{
    color: rgb(0, 0, 0);
}
.text-center{
    text-align: center;
}
.text-vertical{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.w-100{
    width:100%;  
}
.spacer{
    display: block;
    height: 4rem;
}
.two-col{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.link{
    cursor: pointer;
}
.upload-icon{
    content: url('../assets/upload.svg');
    padding-right: 1.5rem;
}
@use 'vars' as *;
@use 'utilities' as *;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');

body {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    color: #151515; /* update this to site body colour */
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}

.clear-float {
    clear:both;
}

.highlight {
    color:#bbbbbb;
    font-size:40px;
}

.button {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    padding: 10px 20px;
    max-width: 100%;
    color: #FFF;
    background-color: $primary-color;
    border: 1px solid $primary-color;
    border-radius: 5px;
    box-sizing: border-box;
    transition: all 0.3s ease;
    font-weight: 500;
    font-size: 14px;
    &:hover {
        color: black;
        background-color: transparent;
    }
}
.button-white{
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    padding: 10px 20px;
    max-width: 100%;
    color: $primary-color;
    background-color: white;
    border: 1px solid $primary-color;
    border-radius: 5px;
    box-sizing: border-box;
    transition: all 0.3s ease;
    font-weight: 500;
    font-size: 14px;
    &:hover {
        color: white;
        background-color: $primary-color;
    }
}

img.display-left {
    float:left;
    max-width: 50%;
    margin-right:20px;
    margin-bottom:10px;
}

img.display-right {
    float:right;
    max-width: 50%;
    margin-left:20px;
    margin-bottom:10px;
}
.pill{
    width:100%;
    display: block;
    text-align: center;
    padding:2rem;
    border-radius: 20px;
    border:1px solid black;
    list-style: none;
    transition: all .3s ease-in-out;
    font-size: 22px;
    &:hover{
        background-color: $primary-color;
        color:#fff;
    }
}

@media only screen and (max-width: 760px){
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
}

